import { Button, Typography } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Exception } from "../../components/Exception";
import { useAuthContext } from "../../hooks/useAuthContext";
import { EnvInfo, User } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

export const Agreement: React.FC<EnvInfo> = ({ isAkfc }) => {
  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);

  const { user, dispatch } = useAuthContext();
  const { fetchFc } = CommonUtils();

  const { Paragraph, Title } = Typography;
  const navigate = useNavigate();

  const handleClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);

    try {
      const body = await fetchFc<User>("/users/acceptAgreement", "POST");
      dispatch!({ type: "LOGIN", payload: body });
      navigate("/");
    } catch (err) {
      setError((err as Error).message);
      setIsPending(false);
    }
  };

  return (
    <div className="content-margin">
      {user && (
        <>
          {isAkfc && user.auth?.isDs && (
            <>
              <Title className="text-center">Confidentiality Agreement</Title>
              {error && <Exception exceptionDetails={error} />}
              <Paragraph>
                <strong>Aga Khan Foundation Canada (AKFC)</strong>
              </Paragraph>
              <Paragraph>
                Confidentiality Agreement for Fund Collection Volunteer
              </Paragraph>
              <Paragraph>
                As AKFC Donor Services Volunteer you will have access to
                personal and confidential information of the donors. You will be
                required to agree and comply with this confidentiality
                agreement.
              </Paragraph>
              <ol>
                <li>
                  You shall not disclose any confidential information to any
                  person, organization or other volunteer committee, without the
                  express written consent of the AKFC Office or when required by
                  law.
                </li>
                <li>
                  Confidential information is information you obtain in the
                  course of your volunteer work with AKFC, and includes, but is
                  not limited to the identity of our donors, their contribution,
                  pledge and payment amounts, their personal information such as
                  address, email address, phone numbers and financial
                  information such as bank or credit card account numbers. You
                  shall exercise due care when handling any financial documents
                  and reports and adhere to the document retention policy
                  guidelines. The obligation not to disclose remains in effect
                  while you are volunteering for AKFC and even after you have
                  ceased to be a volunteer of AKFC. Upon completion of your
                  volunteer term, you shall return to AKFC all documents and
                  other property of AKFC.
                </li>
              </ol>
              <Paragraph>
                I have read and accepted terms and conditions of the
                confidentiality agreement.
              </Paragraph>
              <Paragraph>
                <strong>Fondation Aga Khan Canada</strong>
              </Paragraph>
              <Paragraph>
                Entente de non-divulgation &agrave; l&rsquo;intention des
                volontaires collecteurs de dons
              </Paragraph>
              <Paragraph>
                &Agrave; titre de volontaire collecteur de dons de la Fondation
                Aga Khan Canada, vous aurez acc&egrave;s &agrave; des
                renseignements personnels et confidentiels sur les donateurs.
                Vous devrez au pr&eacute;alable consentir et vous conformer
                &agrave; cette entente de non-divulgation.
              </Paragraph>
              <ol>
                <li>
                  Vous ne divulguerez aucun renseignement personnel &agrave;
                  d&rsquo;autres personnes, organismes ou comit&eacute;s de
                  volontaires, sans avoir au pr&eacute;alable obtenu le
                  consentement expr&egrave;s et &eacute;crit du bureau de la
                  Fondation, ou si la loi l&rsquo;exige;.
                </li>
                <li>
                  Les renseignements confidentiels sont les renseignements que
                  vous obtenez dans le cadre de votre b&eacute;n&eacute;volat
                  avec la Fondation, notamment, mais sans s&rsquo;y limiter,
                  l&rsquo;identit&eacute; de vos donateurs, le montant de leur
                  don, de leur promesse de don et de leur paiement, les
                  renseignements personnels tels que l&rsquo;adresse, le
                  num&eacute;ro de t&eacute;l&eacute;phone et les renseignements
                  bancaires tels que les num&eacute;ros de compte en banque
                  et/ou de carte de cr&eacute;dit. Vous ferez preuve de
                  diligence raisonnable lorsque vous manipulerez des documents
                  et rapports &agrave; teneur financi&egrave;re, et
                  adh&egrave;rerez aux directives de la politique de
                  pr&eacute;servation des documents. Le devoir de
                  non-divulgation demeurera en vigueur pendant toute la
                  p&eacute;riode de votre volontariat aupr&egrave;s de la
                  Fondation, ainsi qu&rsquo;apr&egrave;s l&rsquo;expiration de
                  ladite p&eacute;riode. &Agrave; la fin de votre p&eacute;riode
                  de volontariat, vous devrez remettre tous les documents et
                  autres articles appartenant &agrave; la Fondation Aga Khan
                  Canada.
                </li>
              </ol>
              <Paragraph>
                J&rsquo;ai lu et approuv&eacute; les modalit&eacute;s et
                conditions de la pr&eacute;sente entente de non-divulgation.
              </Paragraph>
            </>
          )}
          {!isAkfc && (
            <>
              <object width="70%" height="700" type="application/pdf" data="/general/help/focus_confidentiality.pdf?#zoom=120&scrollbar=1&toolbar=1&navpanes=0">
                <p>PDF cannot be displayed</p>
              </object>
              <br />
            </>
          )}
          {isAkfc && user.auth?.isJsf && (
            <>
              <Paragraph>
                <strong>Council for Canada</strong>
              </Paragraph>
              <Paragraph>
                This Confidentiality and Non-Disclosure Agreement is given to
                the His Highness and Prince Aga Khan Shia Imami Ismaili Council
                for Canada, its Local Council, and its affiliated Institutions
                (the "Institutions"), in consideration of being a volunteer for
                the Institutions.
              </Paragraph>
              <ol>
                <li>
                  I understand that during the course of my volunteer duties
                  with the Institutions, I will gain knowledge of, or have
                  access to, information that is of a personal, financial,
                  confidential, or proprietary nature, for example, personal
                  information about members of the Jamat and their families such
                  as names, contact information, credit card information or
                  other financial information; or information related to
                  strategic initiatives of the Institutions (such information
                  herein collectively referred to as, "Information").
                </li>
                <li>
                  I acknowledge that all Information, regardless of the form in
                  which it is recorded, transmitted, observed or expressed, or
                  to which it may be converted or transcribed, shall include,
                  without limitation, written and electronically stored or
                  accessible information and data,
                  <span
                    style={{ textDecoration: "underline", fontWeight: "bold" }}
                  >
                    is strictly confidential
                  </span>
                  and I agree that I shall not reveal to any person or entity,
                  or use any Information at any time, except as expressly
                  directed by the Institutions.
                </li>
                <li>
                  I shall hold all Information in trust and strict confidence
                  and confirm that the Information shall be used only for the
                  purposes required to fulfill my volunteer duties, and shall
                  not be used for any other purpose.
                </li>
                <li>
                  I confirm that at any time at the request of the Institutions,
                  I will immediately return or cause to be returned to the
                  Institutions Information in my possession and shall not retain
                  any copies or other reproductions, reports, extracts, notes or
                  memorandums thereof (whether written, electronic, magnetic or
                  otherwise).
                </li>
                <li>
                  I acknowledge and agree that my obligation of confidentiality
                  under paragraph 2 is of indefinite duration and that I will
                  never disclose any Information to any person or entity, except
                  as expressly directed by the Institutions.
                </li>
                <li>
                  I undertake and agree that no Information will be distributed,
                  altered, copied in whatever form, interfered with or
                  destroyed, except in accordance with the instructions of the
                  Institutions.
                </li>
                <li>
                  I agree to use extreme caution with, and take all steps to
                  safeguard, the confidentiality of any part of the Information
                  that may come into my possession at any time or in any place,
                  and in particular when using any type of electronic device or
                  when performing my duties.
                </li>
                <li>
                  I agree that a breach of the obligations contained in this
                  agreement will entail the immediate termination of my
                  voluntary service and may result in damages being assessed
                  against me.
                </li>
              </ol>
              <Paragraph>Read and Agreed</Paragraph>
            </>
          )}
          {!isPending && (
            <Button type="primary" onClick={handleClick}>
              Accept Agreement
            </Button>
          )}
          {isPending && (
            <Button type="primary" disabled>
              Accepting Agreement...
            </Button>
          )}
        </>
      )}
    </div>
  );
};

import { Menu, MenuTheme, Typography } from "antd";
import { Link } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import { useCacheContext } from "../hooks/useCacheContext";
import { FLOW_TYPE, ReportType } from "../types";
import { CommonUtils } from "../utils/CommonUtils";

type MainMenuInput = {
  theme?: MenuTheme;
};

export const MainMenu: React.FC<MainMenuInput> = ({ theme }) => {
  const { user } = useAuthContext();
  const { SubMenu } = Menu;
  const { Link: AntLink } = Typography;

  const { envInfo } = useCacheContext();

  const downloadHelpFile = async (fileName: string) => {
    const { fetchBlob } = CommonUtils();

    try {
      const blob = await fetchBlob(`/general/help/${fileName}`);
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    } catch (err) {
      throw err;
    }
  };

  /* 
	Logic to handle popup overlay issue on Submenu hover event
	get the latest Open Key based on last hover event(where the overlay key gets stuck), 
	check the OpenKeys prop which is array to test if it has more than one key(one from the hover event and other one which is stuck) , 
	if so filter out the lastOpen key which was stuck causing the submenu overlay
	*/
  const onOpenChange = (keys: string[]) => {
    if (keys.length > 1) {
      keys.splice(0, 1);
    }
  };

  return (
    <Menu
      style={{ width: "256px", margin: "10px" }}
      mode="vertical"
      theme={theme ? theme : "dark"}
      onOpenChange={onOpenChange}
    >
      {user?.auth?.isAdmin && (
        <SubMenu key="AdminMenu" title="Admin Actions">
          <Menu.Item key="uploadUserFile">
            <Link to="/uploadUserFile">Upload User File</Link>
          </Menu.Item>
          <Menu.Item key="editUserBulk">
            <Link to="/editUserBulk">Edit Users Bulk</Link>
          </Menu.Item>
          <Menu.Item key="sendReminderEmails">
            <Link to="/sendReminderEmails">Send Reminder Emails</Link>
          </Menu.Item>
          <Menu.Item key="sendDonorChangeEmail">
            <Link to="/sendDonorChangeEmail">Send Donor Change Emails</Link>
          </Menu.Item>
          <Menu.Item key="searchTransactions">
            <Link to="/searchTransactions">Search Transactions</Link>
          </Menu.Item>
          <Menu.Item key="uploadFile">
            <Link to="/uploadFile">Upload File</Link>
          </Menu.Item>
        </SubMenu>
      )}
      {user?.auth?.isAdminOrAkfc && (
        <SubMenu
          key="AKFCMenu"
          title={envInfo?.isAkfc ? "AKFC Actions" : "Focus Actions"}
        >
          <Menu.Item key="uploadDonorFile">
            <Link to="/uploadDonorFile">Upload Donor File</Link>
          </Menu.Item>
          <Menu.Item key="issuePledgeBulk">
            <Link to="/issuePledgeBulk">Bulk - Issue Form(s)</Link>
          </Menu.Item>
          <Menu.Item key="receivePledgeForms">
            <Link
              to="/pledgeAssignment"
              state={{
                flowType: FLOW_TYPE.RECEIVE_JK_COPY,
              }}
            >
              Receive Pledge Forms
            </Link>
          </Menu.Item>
          <Menu.Item key="donorExtract">
            <Link
              to="/donorReport"
              state={{ reportType: ReportType.DONOR_EXTRACT }}
            >
              Donor Extract
            </Link>
          </Menu.Item>
        </SubMenu>
      )}
      <SubMenu key="DonorMenu" title="Donor Actions">
        {(user?.auth?.isDs || user?.auth?.isCfc) && (
          <>
            <Menu.Item key="addDonor">
              <Link to="/addDonor">Add Donor</Link>
            </Menu.Item>
            <Menu.Item key="changeDonorDetails">
              <Link to="/searchDonors">Change Donor Details</Link>
            </Menu.Item>
          </>
        )}
        <Menu.Item key="donorDetails">
          <Link to="/searchDonors">Donor Details</Link>
        </Menu.Item>
        <Menu.Item key="searchDonors">
          <Link to="/searchDonors">Search Donors</Link>
        </Menu.Item>
      </SubMenu>
      {(user?.auth?.isDs || user?.auth?.isCfc) && (
        <SubMenu
          key="FormMenu"
          title={envInfo?.messagesMap["main.pledgeActions"]}
        >
          {user?.auth?.canDistributeForms && (
            <>
              <Menu.Item key="distributePledgeFormsToJk">
                <Link
                  to="/pledgeAssignment"
                  state={{ flowType: FLOW_TYPE.ISSUE }}
                >
                  {envInfo?.messagesMap["main.issuePledges"]}
                </Link>
              </Menu.Item>
              <Menu.Item key="reassignPledgeForms">
                <Link
                  to="/pledgeAssignment"
                  state={{ flowType: FLOW_TYPE.REASSIGN }}
                >
                  {envInfo?.messagesMap["main.reassignPledges"]}
                </Link>
              </Menu.Item>
            </>
          )}
          {!envInfo?.isAkfc && user?.auth?.canReceiveForms && (
            <Menu.Item key="receivePledgeFormsInJk">
              <Link
                to="/pledgeAssignment"
                state={{ flowType: FLOW_TYPE.RECEIVE }}
              >
                {envInfo?.messagesMap["main.receivePledges"]}
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key="enterPledgeForms">
            <Link to="/searchDonors">
              {envInfo?.messagesMap["main.enterPledge"]}
            </Link>
          </Menu.Item>
          <Menu.Item key="searchPledges">
            <Link to="/searchPledges">
              {envInfo?.messagesMap["main.searchPledges"]}
            </Link>
          </Menu.Item>
        </SubMenu>
      )}
      {user?.auth?.canCreateDeposit && (
        <SubMenu key="DepositMenu" title="Batch / Deposit Actions">
          <Menu.Item key="createDeposit">
            <Link to="/editDeposit" state={{ flowType: FLOW_TYPE.CREATE }}>
              Create Deposit
            </Link>
          </Menu.Item>
          <Menu.Item key="createBatch">
            <Link to="/createBatch" state={{ flowType: FLOW_TYPE.CREATE }}>
              Create Batch
            </Link>
          </Menu.Item>
          <Menu.Item key="unusedBatch">
            <Link to="/createBatch" state={{ flowType: FLOW_TYPE.UNUSED }}>
              {envInfo?.messagesMap["main.returnUnusedPledges"]}
            </Link>
          </Menu.Item>
          <Menu.Item key="searchDeposits">
            <Link to="/searchDeposits">Search Deposits</Link>
          </Menu.Item>
          <Menu.Item key="searchBatches">
            <Link to="/searchBatches">Search Batches</Link>
          </Menu.Item>
        </SubMenu>
      )}
      {(user?.auth?.canAddUsers || user?.auth?.isDs) && (
        <SubMenu key="UserMenu" title="User Actions">
          {user?.auth?.canAddUsers && (
            <>
              <Menu.Item key="addUser">
                <Link to="/addUser">Add User</Link>
              </Menu.Item>
              <Menu.Item key="editUser">
                <Link to="/searchUsers">Edit User</Link>
              </Menu.Item>
            </>
          )}
          <Menu.Item key="userDetails">
            <Link to="/searchUsers">User Details</Link>
          </Menu.Item>
          <Menu.Item key="searchUsers">
            <Link to="/searchUsers">Search Users</Link>
          </Menu.Item>
        </SubMenu>
      )}
      {user?.auth?.canRunReports && (
        <SubMenu key="Reports" title="Report Actions">
          <Menu.Item key="pledgeReport">
            <Link to="/pledgeReport" state={{ reportType: ReportType.PLEDGE }}>
              DIS Pledge Report
            </Link>
          </Menu.Item>
          {(user?.auth?.isDs || user?.auth?.isCfc) && (
            <>
              <Menu.Item key="pledgeStatusReport">
                <Link
                  to="/pledgeReport"
                  state={{ reportType: ReportType.PLEDGE_STATUS }}
                >
                  {envInfo?.messagesMap["main.pledgeStatusReport"]}
                </Link>
              </Menu.Item>
              <Menu.Item key="batchReport">
                <Link
                  to="/batchReport"
                  state={{ reportType: ReportType.BATCH }}
                >
                  {envInfo?.messagesMap["main.batchReport"]}
                </Link>
              </Menu.Item>
              <Menu.Item key="batchSummaryReport">
                <Link
                  to="/batchReport"
                  state={{ reportType: ReportType.BATCH_SUMMARY }}
                >
                  {envInfo?.messagesMap["main.batchSummaryReport"]}
                </Link>
              </Menu.Item>
              <Menu.Item key="depositReport">
                <Link
                  to="/depositReport"
                  state={{ reportType: ReportType.DEPOSIT }}
                >
                  Deposit Report
                </Link>
              </Menu.Item>
              <Menu.Item key="DONOR_CHANGE">
                <Link
                  to="/donorReport"
                  state={{ reportType: ReportType.DONOR_CHANGE }}
                >
                  Donor Change Report
                </Link>
              </Menu.Item>
            </>
          )}
          <Menu.Item key="donorReport">
            <Link to="/donorReport" state={{ reportType: ReportType.DONOR }}>
              All Donor Report
            </Link>
          </Menu.Item>
          <Menu.Item key="DONOR_FOLLOW_UP">
            <Link
              to="/donorReport"
              state={{ reportType: ReportType.DONOR_FOLLOW_UP }}
            >
              Donor Follow-Up Report
            </Link>
          </Menu.Item>
          {envInfo?.isAkfc && (
            <Menu.Item key="DONOR_EDIT">
              <Link
                to="/donorReport"
                state={{ reportType: ReportType.DONOR_EDIT }}
              >
                Donor Edit Report
              </Link>
            </Menu.Item>
          )}
          {!user.auth.isJsfJkConvenor && (
            <Menu.Item key="DONOR_ALTERNATE_JK">
              <Link
                to="/donorReport"
                state={{ reportType: ReportType.DONOR_ALTERNATE_JK }}
              >
                Donations made at alternate JK's
              </Link>
            </Menu.Item>
          )}
          {user.auth.canAddUsers && (
            <>
              <Menu.Item key="userReport">
                <Link to="/userReport" state={{ reportType: ReportType.USER }}>
                  DIS User Report
                </Link>
              </Menu.Item>
              <Menu.Item key="userDutyReport">
                <Link
                  to="/userReport"
                  state={{ reportType: ReportType.USER_DUTY }}
                >
                  DIS User Duty Report
                </Link>
              </Menu.Item>
            </>
          )}
        </SubMenu>
      )}
      <SubMenu key="Help" title="Help">
        {envInfo?.isAkfc && (
          <>
            <Menu.Item key="jsf_donations_processing.pdf">
              <AntLink
                onClick={() => downloadHelpFile("jsf_donations_processing.pdf")}
              >
                JSF Donations Processing Presentation
              </AntLink>
            </Menu.Item>
            <Menu.Item key="jsf_quick_notes.pdf">
              <AntLink onClick={() => downloadHelpFile("jsf_quick_notes.pdf")}>
                JSF Pledge Form Samples
              </AntLink>
            </Menu.Item>
            <Menu.Item key="jsf_reports_overview.pdf">
              <AntLink
                onClick={() => downloadHelpFile("jsf_reports_overview.pdf")}
              >
                JSF Reports Overview for JSF Team
              </AntLink>
            </Menu.Item>
            <Menu.Item key="jsf_reports_overview_ds.pdf">
              <AntLink
                onClick={() => downloadHelpFile("jsf_reports_overview_ds.pdf")}
              >
                JSF Reports Overview for Donor Services
              </AntLink>
            </Menu.Item>
            <Menu.Item key="faqs.pdf">
              <AntLink onClick={() => downloadHelpFile("faqs.pdf")}>
                Frequently Asked Questions
              </AntLink>
            </Menu.Item>
          </>
        )}
        {!envInfo?.isAkfc && (
          <>
            <Menu.Item key="focus_campaign_overview.pdf">
              <AntLink
                onClick={() => downloadHelpFile("focus_campaign_overview.pdf")}
              >
                Focus Campaign Overview
              </AntLink>
            </Menu.Item>
            <Menu.Item key="focus_campaign_process.pdf">
              <AntLink
                onClick={() => downloadHelpFile("focus_campaign_process.pdf")}
              >
                Focus Campaign Process
              </AntLink>
            </Menu.Item>
            <Menu.Item key="completing_focus_drf.pdf">
              <AntLink
                onClick={() => downloadHelpFile("completing_focus_drf.pdf")}
              >
                Completing Focus DR Form
              </AntLink>
            </Menu.Item>
            <Menu.Item key="focus_faqs.pdf">
              <AntLink onClick={() => downloadHelpFile("focus_faqs.pdf")}>
                Frequently Asked Questions
              </AntLink>
            </Menu.Item>
            <Menu.Item key="focus_dis_training.pdf">
              <AntLink
                onClick={() => downloadHelpFile("focus_dis_training.pdf")}
              >
                Focus DIS Training
              </AntLink>
            </Menu.Item>
          </>
        )}
      </SubMenu>
    </Menu>
  );
};

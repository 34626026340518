import { Button, Form, InputNumber, Spin, Typography } from "antd";
import { Rule } from "antd/lib/form";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Exception } from "../../components/Exception";
import { JkSelect } from "../../components/JkSelect";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCacheContext } from "../../hooks/useCacheContext";
import { ConfirmDetails, FLOW_TYPE, Pledge, User } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

type Display = {
  flowType: FLOW_TYPE;
};

type FORM_DISPLAY = {
  header: string;
  submitButton: string;
  showNewJk: boolean;
  restUrl: string;
};

export const PledgeAssignment = () => {
  const { state }: { state: any } = useLocation();
  const display: Display = state;

  const { user: _sessionUser } = useAuthContext();
  const sessionUser = useRef<User>(_sessionUser).current as User;

  const [formDisplay, setFormDisplay] = useState<FORM_DISPLAY | null>(null);

  const [form] = Form.useForm();
  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);

  const { isCacheReady, envInfo, minFormNumberDigits } = useCacheContext();

  const { Title } = Typography;
  const navigate = useNavigate();

  const initFormValues = {
    startNumber: undefined,
    endNumber: undefined,
    region: { id: sessionUser?.region?.id },
    jamatKhana: { id: sessionUser?.jamatKhana?.id },
  };

  useEffect(() => {
    setError(null);

    let formDisplay: FORM_DISPLAY;
    if (display.flowType === FLOW_TYPE.ISSUE) {
      formDisplay = {
        header: envInfo!.messagesMap["main.issuePledges"],
        submitButton: envInfo!.messagesMap["addPledgesSubmit"],
        showNewJk: false,
        restUrl: "/pledges/issuePledges",
      };
    } else if (display.flowType === FLOW_TYPE.REASSIGN) {
      formDisplay = {
        header: envInfo!.messagesMap["main.reassignPledges"],
        submitButton: envInfo!.messagesMap["reassignPledgesSubmit"],
        showNewJk: true,
        restUrl: "/pledges/reassignPledges",
      };
    } else {
      formDisplay = {
        header: envInfo!.messagesMap["main.receivePledges"],
        submitButton: envInfo!.messagesMap["receivePledgesSubmit"],
        showNewJk: false,
        restUrl: "/pledges/receivePledgesAtJK",
      };
    }

    setFormDisplay(formDisplay);
  }, [display, form, envInfo]);

  const { fetchFc, getUserJk, validateFormNumber } = CommonUtils();

  const onFinish = async (form: Pledge) => {
    setIsPending(true);
    setError(null);

    try {
      if (form.startNumber! > form.endNumber!) {
        setError("Starting Number cannot be greater than the Ending Number");
        setIsPending(false);
        return;
      }

      const input: Pledge = {
        ...form,
        flowType: display.flowType,
      };

      const result = await fetchFc<any>(formDisplay!.restUrl, "POST", input);

      const confirmDetails: ConfirmDetails = {
        title: formDisplay?.header + " Successful",
        subTitle: `${result.formsImpacted} forms created / updated`,
      };

      navigate("/confirm", { state: confirmDetails });
    } catch (err) {
      const errorMessage = (err as Error).message;
      setError(errorMessage);
      setIsPending(false);
    }
  };

  const formNumValidator = async (_: Rule, value: number) => {
    validateFormNumber(
      envInfo?.isAkfc ? true : false,
      minFormNumberDigits,
      value
    );

    return Promise.resolve();
  };

  return (
    <div>
      <>
        {(!isCacheReady || !display) && (
          <div className="loading">
            <Spin size="large" />
          </div>
        )}
        {isCacheReady && display && (
          <>
            <Title className="text-center">{formDisplay?.header}</Title>
            {error && <Exception exceptionDetails={error} />}
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 8 }}
              onFinish={onFinish}
              autoComplete="off"
              initialValues={{
                ...initFormValues,
                jamatKhana: getUserJk(sessionUser),
              }}
            >
              <Form.Item
                label="Start Number"
                name="startNumber"
                rules={[
                  {
                    required: true,
                    validator: formNumValidator,
                  },
                ]}
              >
                <InputNumber
                  placeholder="Starting Number"
                  style={{ width: "100%" }}
                  controls={false}
                  min={0}
                  precision={0}
                />
              </Form.Item>
              <Form.Item
                label="End Number"
                name="endNumber"
                rules={[
                  {
                    required: true,
                    validator: formNumValidator,
                  },
                ]}
              >
                <InputNumber
                  placeholder="Ending Number"
                  style={{ width: "100%" }}
                  controls={false}
                  min={0}
                  precision={0}
                />
              </Form.Item>
              <JkSelect form={form} isRequired={true} />
              {formDisplay?.showNewJk && (
                <JkSelect
                  form={form}
                  isRequired={true}
                  namePath="newJkId"
                  label="New Jamat Khana"
                />
              )}
              <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                {!isPending && (
                  <Button type="primary" htmlType="submit">
                    {formDisplay?.submitButton}
                  </Button>
                )}
                {isPending && (
                  <Button type="primary" disabled>
                    Processing...
                  </Button>
                )}
              </Form.Item>
            </Form>
          </>
        )}
      </>
    </div>
  );
};

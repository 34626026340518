import { JamatKhana, User } from "../types";

export const CommonUtils = () => {
  async function fetchFc<T>(
    url: string,
    method: string,
    data: any | null = null
  ): Promise<T> {
    let response: Response | null = null;
    let bodyData: string | null = null;

    const csrfToken = await getCsrfToken();

    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-XSRF-TOKEN", csrfToken);

    try {
      if (method === "GET") {
        if (data) {
          bodyData = serialize(data);
        }

        response = await fetch(url + (bodyData ? "?" + bodyData : ""), {
          headers,
          redirect: "error",
        });
      } else {
        bodyData = JSON.stringify(data);
        response = await fetch(url, {
          method: method,
          headers,
          body: bodyData,
          redirect: "error",
        });
      }
    } catch (err) {
      if (
        window.location.pathname !== "/" &&
        window.location.pathname !== "/login"
      ) {
        sessionStorage.clear();
        sessionStorage.setItem("sessionTimedOut", "true");
        window.location.replace("/");
      }

      throw err;
    }

    const text = await response.text();

    if (!response.ok) {
      handleFetchError(text, response);
    }

    return text ? (JSON.parse(text) as Promise<T>) : ({} as Promise<T>);
  }

  async function fetchBlob(
    url: string,
    data: any | null = null
  ): Promise<Blob> {
    let bodyData: string | null = null;

    if (data) {
      bodyData = serialize(data);
    }

    let response: Response;
    try {
      response = await fetch(
        url + (bodyData ? "?" + bodyData.toString() : ""),
        { redirect: "error" }
      );
    } catch (err) {
      if (
        window.location.pathname !== "/" &&
        window.location.pathname !== "/login"
      ) {
        sessionStorage.clear();
        sessionStorage.setItem("sessionTimedOut", "true");
        window.location.replace("/");
      }

      throw err;
    }

    if (!response!.ok) {
      const text = await response!.text();
      handleFetchError(text, response);
    }

    const blob = await response.blob();
    return blob;
  }

  async function uploadFile<T>(url: string, data: FormData): Promise<T> {
    let response: Response | null = null;

    const csrfToken = await getCsrfToken();

    let headers = new Headers();
    headers.append("X-XSRF-TOKEN", csrfToken);

    try {
      response = await fetch(url, {
        method: "POST",
        headers,
        body: data,
        redirect: "error",
      });
    } catch (err) {
      if (
        window.location.pathname !== "/" &&
        window.location.pathname !== "/login"
      ) {
        sessionStorage.clear();
        sessionStorage.setItem("sessionTimedOut", "true");
        window.location.replace("/");
      }

      throw err;
    }

    const text = await response.text();

    if (!response.ok) {
      handleFetchError(text, response);
    }

    return text ? (JSON.parse(text) as Promise<T>) : ({} as Promise<T>);
  }

  const formatPhoneNumber = (phoneNumber?: string): string => {
    if (phoneNumber) {
      const cleaned = ("" + phoneNumber).replace(/\D/g, "");
      const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        const intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
    }

    return "";
  };

  const formatPostalCode = (postalCode?: string): string => {
    if (postalCode) {
      return postalCode
        .toUpperCase()
        .replace(/\W/g, "")
        .replace(/(...)/, "$1 ");
    }

    return "";
  };

  const formatBoolean = (bool?: boolean): string => {
    return bool ? "Yes" : "No";
  };

  const formatDate = (timeStr?: string, dateOnly: boolean = false): string => {
    if (timeStr) {
      let options: Intl.DateTimeFormatOptions;
      if (dateOnly) {
        options = {
          month: "long",
          day: "numeric",
          year: "numeric",
        };
      } else {
        options = {
          month: "long",
          day: "numeric",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };
      }

      const time = new Date(timeStr);

      return time.toLocaleDateString("en-US", options);
    } else {
      return "";
    }
  };

  const formatCurrency = (
    amount?: number,
    decimalPlaces: number = 2,
    withCurrencySymbol: boolean = true
  ): string => {
    return amount
      ? (withCurrencySymbol ? "$" : "") +
          formatNumber(amount, decimalPlaces).toLocaleString(undefined, {
            minimumFractionDigits: decimalPlaces,
          })
      : "";
  };

  const formatNumber = (num: number, decimalPlaces: number): number => {
    return Number(
      Math.round(parseFloat(num + "e" + decimalPlaces)) + "e-" + decimalPlaces
    );
  };

  const stringComparator = (a?: string, b?: string): number => {
    const a1 = a || "";
    const b1 = b || "";
    return a1.localeCompare(b1);
  };

  const processUserAuth = (user: User) => {
    const isAdmin = user.currentRoleName === "admin";
    const isAkfc = user.currentRoleName === "akfc";
    const isAdminOrAkfc = isAdmin || isAkfc;
    const isCfcPm = user.currentRoleName === "cfc_pm";
    const isCfcVolunteer = user.currentRoleName === "cfc_volunteer";
    const isNatReports = user.currentRoleName === "national_reports";
    const isDsRegionalConvenor = user.currentRoleName === "regional_convenor";
    const isDsJkConvenor = user.currentRoleName === "ds_jk_coordinator";
    const isDsJkVolunteer = user.currentRoleName === "ds_jk_volunteer";
    const isJsfRegionalConvenor =
      user.currentRoleName === "jsf_regional_convenor";
    const isJsfJkConvenor = user.currentRoleName === "jsf_jk_coordinator";
    const isCfc = isAdminOrAkfc || isCfcPm || isCfcVolunteer;
    const allRegionsForDisplay = isAdminOrAkfc || isNatReports || isCfc;
    const allJksForDisplay =
      isAdminOrAkfc ||
      isNatReports ||
      isDsRegionalConvenor ||
      isJsfRegionalConvenor ||
      isCfc;
    const canDistributeForms = isAdminOrAkfc || isDsRegionalConvenor || isCfc;
    const canCreateDeposit =
      isAdminOrAkfc || isDsRegionalConvenor || isDsJkConvenor || isCfc;
    const isDs =
      isAdminOrAkfc ||
      isDsRegionalConvenor ||
      isDsJkConvenor ||
      isDsJkVolunteer;
    const isJsf =
      isCfc || isJsfRegionalConvenor || isJsfJkConvenor || isNatReports;
    const canAddUsers =
      isAdminOrAkfc ||
      isDsRegionalConvenor ||
      isDsJkConvenor ||
      isJsfRegionalConvenor ||
      isCfcPm ||
      isNatReports;
    const canReceiveForms = canDistributeForms || isDsJkConvenor;
    const canRunReports = !isDsJkVolunteer;
    const regionSummaryReport =
      allRegionsForDisplay || isDsRegionalConvenor || isJsfRegionalConvenor;
    const canUpdateDonors =
      isAdminOrAkfc || isCfc || isDsRegionalConvenor || isDsJkConvenor;

    user.auth = {
      isAdmin,
      isAkfc,
      isAdminOrAkfc,
      isDsRegionalConvenor,
      isDsJkConvenor,
      allRegionsForDisplay,
      allJksForDisplay,
      canDistributeForms,
      canCreateDeposit,
      isDs,
      isJsf,
      canAddUsers,
      canReceiveForms,
      canRunReports,
      regionSummaryReport,
      canUpdateDonors,
      isJsfJkConvenor,
      isCfc,
    };
  };

  const getJksByRegion = (
    regionId: number,
    jks: JamatKhana[]
  ): JamatKhana[] => {
    let jkDisplay: JamatKhana[] = [];
    if (regionId > 0) {
      jkDisplay = [
        { id: -1, name: " " },
        ...jks.filter((jk) => jk.region?.id === regionId),
      ];
    } else {
      jkDisplay = [{ id: -1, name: " " }];
    }

    return jkDisplay;
  };

  const serialize = function (obj: any, prefix?: string): string {
    const str = [];
    let p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        const k = prefix ? prefix + "." + p : p;
        const v = obj[p];

        if (v) {
          str.push(
            typeof v === "object"
              ? serialize(v, k)
              : encodeURIComponent(k) + "=" + encodeURIComponent(v)
          );
        }
      }
    }

    return str.join("&");
  };

  const downloadFile = (resBlob: Blob, fileName: string) => {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const blob = new Blob([resBlob], {
      type: resBlob.type || "application/octet-stream",
    });

    if (typeof window.navigator.msSaveBlob !== "undefined") {
      // IE doesn't allow using a blob object directly as link href.
      // Workaround for "HTML7007: One or more blob URLs were
      // revoked by closing the blob for which they were created.
      // These URLs will no longer resolve as the data backing
      // the URL has been freed."
      window.navigator.msSaveBlob(blob, fileName);
      return;
    }

    // Other browsers
    // Create a link pointing to the ObjectURL containing the blob
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", fileName);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(blobURL);
    }, 100);
  };

  const isStringDigitsOnly = (value: string) => {
    return /^\d+$/.test(value);
  };

  const pledgeStatus = ["", "Active", "Fulfilled", "Outstanding", "Cancelled"];

  const booleanArray = [
    { label: "", value: "" },
    { label: "No", value: false },
    { label: "Yes", value: true },
  ];

  const yesNoArray = [
    { label: "", value: "" },
    { label: "No", value: "No" },
    { label: "Yes", value: "Yes" },
  ];

  const ageRangeArray = [
    "",
    "17 and under",
    "18-24",
    "25-35",
    "36-45",
    "46-55",
    "55+",
  ];

  const cashCollection = ["Cash"];
  const checkCollection = ["Personal Cheque", "Business Cheque"];
  const pdcCollection = [
    "Personal Post Dated Cheques",
    "Business Post Dated Cheques",
  ];
  const allCheckCollections = [...checkCollection, ...pdcCollection];
  const allCollections = [
    ...cashCollection,
    ...checkCollection,
    ...pdcCollection,
  ];
  const creditCardTypes = [
    "Visa",
    "MasterCard",
    "American Express",
    "Discover",
  ];

  const provinceArray = [
    { abbr: "AB", name: "Alberta" },
    { abbr: "BC", name: "British Columbia" },
    { abbr: "MB", name: "Manitoba" },
    { abbr: "NB", name: "New Brunswick" },
    { abbr: "NL", name: "Newfoundland and Labrador" },
    { abbr: "NS", name: "Nova Scotia" },
    { abbr: "ON", name: "Ontario" },
    { abbr: "PE", name: "Prince Edward Island" },
    { abbr: "QC", name: "Quebec" },
    { abbr: "SK", name: "Saskatchewan" },
    { abbr: "NT", name: "Northwest Territories" },
    { abbr: "NU", name: "Nunavut" },
    { abbr: "YT", name: "Yukon" },
    { abbr: "N/A", name: "N/A" },
  ];

  const countryArray = ["Canada", "United States", "Other"];

  const getUserJk = (sessionUser: User): JamatKhana => {
    const regionId =
      sessionUser.region?.id || sessionUser.jamatKhana?.region?.id;
    const jkId = sessionUser.jamatKhana?.id;
    return { id: jkId || -1, region: { id: regionId || -1 } };
  };

  const validatePhoneNumber = (phoneNumber?: string): boolean => {
    if (phoneNumber) {
      const cleaned = ("" + phoneNumber).replace(/\D/g, "");
      const phoneNumberPattern = /^\d{10}$/;
      return phoneNumberPattern.test(cleaned);
    }

    return false;
  };

  const validateFormNumber = (
    isAkfc: boolean,
    minFormNumberDigits: number,
    value?: number
  ): boolean => {
    if (!value) {
      throw new Error("Form number is required");
    }

    if (("" + value).length !== minFormNumberDigits) {
      throw new Error(
        `${
          isAkfc ? "CFC" : "Focus"
        } Forms must be ${minFormNumberDigits} digits`
      );
    }

    return true;
  };

  const validateNumberField = (num?: string, checkDigits?: number): boolean => {
    if (num) {
      if (!checkDigits) {
        return /^\d+$/.test(num);
      } else {
        return num.length === checkDigits && /^\d+$/.test(num);
      }
    }

    return false;
  };

  const validateDepositNumber = (value?: string): boolean => {
    if (!value) {
      throw new Error("Deposit Number is required");
    }

    if (value.length !== 12 || !/^\d+$/.test(value)) {
      throw new Error("Deposit number must be 12 digits");
    }

    return true;
  };

  return {
    fetchFc,
    fetchBlob,
    formatPhoneNumber,
    formatCurrency,
    formatNumber,
    formatDate,
    processUserAuth,
    getJksByRegion,
    serialize,
    uploadFile,
    downloadFile,
    isStringDigitsOnly,
    pledgeStatus,
    formatPostalCode,
    formatBoolean,
    booleanArray,
    yesNoArray,
    ageRangeArray,
    cashCollection,
    checkCollection,
    pdcCollection,
    allCheckCollections,
    allCollections,
    creditCardTypes,
    provinceArray,
    countryArray,
    getUserJk,
    stringComparator,
    validatePhoneNumber,
    validateFormNumber,
    validateDepositNumber,
    validateNumberField,
  };
};

const handleFetchError = (text: string | null, response: Response) => {
  if (!text) {
    throw new Error(response.statusText);
  } else {
    throw new Error(text);
  }
};

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

const getCsrfToken = async (): Promise<string> => {
  let csrfToken = document.cookie.replace(
    /(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/,
    "$1"
  );

  if (!csrfToken) {
    let response = await fetch("/general/envInfo");
    let envInfo = await response.json();
    sessionStorage.setItem("envInfo", JSON.stringify(envInfo));

    csrfToken = document.cookie.replace(
      /(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
  }

  return csrfToken;
};
